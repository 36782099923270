<template>
   <div>
      <line-chart 
      :data="getData"
      @reload-icon-click="updateDataList"/>
      <button v-on:click="download">다운로드</button>
   </div>
</template>

<script>
import LineChart from '../components/Charts/LineChart'
import { useRoute } from 'vue-router'
import { useStore } from "vuex"
import { onMounted, computed } from 'vue'

const dynamicFile = 'dynamicFile'

export default {
   components: {
    LineChart,
  },
   setup() {
      const store = useStore()
      const route = useRoute()

      const getData = computed(() => store.getters[`${dynamicFile}/getData`])

      onMounted(() => {
         console.log('onMounted');
         console.log(route.params.url);
         store.dispatch(`${dynamicFile}/actionDynamicFile`, route.params.url)
      })

      const updateDataList = () => {
         console.log('update');
         // let result = store.dispatch(`${measureData}/actionMeasureDataList`, route.params.id)
         // authErrorHandle(result, router)
      }

      const download = () => {
         store.dispatch(`${dynamicFile}/actionDownload`)
      }

      const getUrl = computed({
         get: () => route.params.url,
      })

      return {
         getUrl,
         getData,
         updateDataList,
         download
      }
   }
}
</script>

<style scoped>
.button {
  border-radius: 8px;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 24px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}
</style>