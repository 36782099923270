<template>
   <div class="menu-form ">
      <button class="button button5" v-on:click="clickStatic">정적 측정</button>
      <button class="button button5" v-on:click="clickDinamic">동적 측정</button>
   </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'

export default {
   setup () {
      const router = useRouter()
      const route = useRoute()

      const clickStatic = () => { 
          router.push(`/list/${route.params.id}`)
      }

      const clickDinamic = () => { 
         router.push(`/dinamic/list/${route.params.id}`)
      }


      return {
        clickStatic,
        clickDinamic
      }
   }
}
</script>

<style scoped>

.menu-form {
   position: absolute;     
   width: 200px;
   top: 50%;
   left: 50%;
   padding: 20px;
   text-align: center;
   border: none;
   margin: -112px 0 0 -100px;
}
 

.button {
  border-radius: 8px;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 24px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button5 {
  background-color: white;
  color: #2962FF;
  border: 2px solid #448AFF;
}

.button5:hover {
  background-color: #448AFF;
  color: white;
}

</style>